import Vue from "vue";
import {safeAccess} from "../safe-access/index";

export class DefaultAuthInfo {
  constructor() {
    this.authData = null;
    this.bridge = false;
  }

  setData(data) {
    this.authData = Vue.observable(data);
    if (this.authData?.loginId) {
      this.bridge = !this.authData.loginId.includes("@");
    }
  }

  get loginId() {
    return safeAccess(this.authData, "loginId");
  }

  get authorities() {
    return safeAccess(this.authData, "authorities") || [];
  }

  get authenticated() {
    return safeAccess(this.authData, "authenticated") || false;
  }

  get isBridge() {
    return this.bridge;
  }
}
