<template>
  <div class="login">
    <div class="login-section">
      <div class="login-section__item">
        <div class="login-section__header">
          <h1><img src="/assets/img/logo.svg" alt="Jet"><span>My Page</span></h1>
          <p>JETの受検管理ができるマイページ</p>
        </div>
      </div>
      <div class="login-section__item">
        <div class="login-section__body">
          <form>
            <alert></alert>
            <input class="c-input__text u-mb10" type="email" placeholder="LeptonNo" v-model="form.loginId">
            <input class="c-input__password u-mb10" type="password" placeholder="パスワード" v-model="form.password">
            <div class="login-section__bottom">
              <button class="c-btn u-mb20 u-ma" :disabled="!filled" @click="login" type="button"><span>ログイン</span></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {AuthService} from "@/lib/service";

export default {
  data() {
    return {
      form: {
        loginId: null,
        password: null,
      }
    }
  },
  created() {
    if (!this.$store.state.notification.keep)
      this.$store.commit("clearNotification");
  },
  computed: {
    filled() {
      return this.form.loginId && this.form.password;
    }
  },
  methods: {
    login() {
      AuthService.bridgeLogin(this.form);
    }
  }
};
</script>
