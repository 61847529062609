import {authInfo} from "./AuthInfo";
import {Api, move} from "@/calico-vue/service";
import {router} from "@/router";

const api = new Api("/system/auth");

export const AuthService = {
  login(form) {
    return api.execute("login", form).then((data) => {
      authInfo.setData(data);
      move(router, "/home");
      return data;
    });
  },
  bridgeLogin(form) {
    return api.execute("bridge_login", form).then((data) => {
      authInfo.setData(data);
      move(router, "/home");
      return data;
    });
  },
  logout() {
    return api.execute("logout").then((data) => {
      authInfo.setData(data);
      if (window.location.hostname === 'gakken.jet-japan.ne.jp') {
        move(router, "/login");
      } else {
        move(router, "/bridge/login");
      }
      return data;
    });
  },
  keep() {
    return api.execute("keep").then((data) => {
      authInfo.setData(data);
      return data;
    }, () => {
    });
  },
  onSessionTimeout() {
    authInfo.setData({});
    this.clearCache();
    move(router, "/login");
  }
};
