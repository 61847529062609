<template>
  <div>
    <mypage-header :no-auth="noAuth" />
    <main class="l-main">
      <div class="contents">
        <slot></slot>
      </div>
    </main>
    <footer class="l-footer">
      <p v-if="!isBridge()"><img src="/assets/img/gakken.svg" alt="Gakken">株式会社学研ホールディングス</p>
      <p class="l-footer__copyright">© 2022 JET Committee.</p>
    </footer>
  </div>
</template>

<script>
import MypageHeader from "./MypageHeader";
import {authInfo} from "@/lib/service";

export default {
  methods: {
    isBridge() {
      return authInfo.isBridge;
    },
  },
  props: {
    noAuth: { type: Boolean, default: false }
  },
  components: {
    MypageHeader
  }
}
</script>
