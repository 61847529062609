import {RouteConfigBuilder} from "@/calico-vue/service";
/**
 * routes
 */
import Login from "./Login"; //遅延ロードしない

const basePath = "/bridge";

const Routes = new RouteConfigBuilder(basePath, {
  allowAll: true,
});
export const routes = [Routes.component("login", Login)];
