<template>
  <default-layout>
    <div class="contents-block has-blue-border">
      <div class="contents-block__body">
        <alert></alert>
        <div class="c-block">
          <!--jetのレベルに合わせて「is-grade5-6」など変更で「級」のフォントカラーが変ります。-->
          <h1 class="c-user" :class="'is-lv' + currentExaminee.gradeCode">
            <img src="/assets/img/icon-home.svg" alt="">
            <span v-if="currentMember" class="c-user__name">{{currentMember.name}}さん</span>
            <span v-if="currentExaminee" class="c-user__level">{{currentExaminee.gradeName}} {{currentExaminee.gradeNameEn}}</span>
          </h1>
        </div>
        <!--開始前-->
        <template v-if="currentExaminee === false">
          <div class="c-block">
            <p class="c-text__lg u-tac" v-if="examineesLoaded">試験の予定はありません。</p>
          </div>
        </template>
        <!--開始前-->
        <template v-else-if="!currentExaminee.started">
          <div class="c-block">
            <p class="c-text__lg u-tac"><strong class="u-red">{{currentExaminee.startTime | date}} 〜 {{currentExaminee.endTime | date}}</strong>の間に受検してください。</p>
          </div>
          <div v-if="isBridge()" class="c-block u-tac">
            <a class="c-link__text c-text__sm pdf-link" href="https://www.jet-japan.ne.jp/docs/bridge/JET-CBT受検ガイド.pdf" target="_blank">JET-CBT 受検ガイド</a>
          </div>
          <div v-else class="c-block u-tac">
            <a class="c-link__text c-text__sm pdf-link" href="https://www.jet-japan.ne.jp/docs/gakken/JET受検の手引き.pdf" target="_blank">JET受検の手引き</a>
            <a class="c-link__text c-text__sm pdf-link u-ml30" href="https://www.jet-japan.ne.jp/docs/gakken/JET-CBT受検ガイド.pdf" target="_blank">JET-CBT 受検ガイド</a>
          </div>
        </template>
        <!--実施期間中-->
        <template v-else-if="currentExaminee.inProgress">
          <div class="c-block">
            <p class="c-text__lg u-tac"><strong class="u-red">{{currentExaminee.startTime | date}} 〜 {{currentExaminee.endTime | date}}</strong>の間に受検してください。</p>
          </div>
          <div class="c-block" v-if="currentExaminee.tookExam">
            <div class="c-block">
              <button class="c-btn is-short u-ma" disabled type="button"><span>既に受検済みです</span></button>
            </div>
          </div>
          <template v-else-if="currentExaminee.gradeCode">
            <div class="c-block">
              <button class="c-btn is-short u-ma" type="button" @click="startExam"><span>JETを受検する</span></button>
            </div>
            <div class="c-block">
              <p class="c-text__sm u-tac u-mb30">※もし試験中に誤って試験画面を閉じてしまった場合は、<br>再度このボタンを押して試験を再開してください。</p>
            </div>
          </template>
          <div v-if="isBridge()" class="c-block u-tac">
            <a class="c-link__text c-text__sm pdf-link" :href="'https://www.jet-japan.ne.jp/docs/bridge/JET-CBT受検ガイド.pdf?id=' + currentExaminee.examineeId" target="_blank">JET-CBT 受検ガイド</a>
          </div>
          <div v-else class="c-block u-tac">
            <a class="c-link__text c-text__sm pdf-link" :href="'https://www.jet-japan.ne.jp/docs/gakken/JET受検の手引き.pdf?id=' + currentExaminee.examineeId" target="_blank">JET受検の手引き</a>
            <a class="c-link__text c-text__sm pdf-link u-ml30" :href="'https://www.jet-japan.ne.jp/docs/gakken/JET-CBT受検ガイド.pdf?id=' + currentExaminee.examineeId" target="_blank">JET-CBT 受検ガイド</a>
          </div>
        </template>
        <!--実施期間終了-->
        <template v-else>
          <div class="c-block">
            <p class="c-text__lg u-tac">試験期間（{{currentExaminee.startTime | date}} 〜 {{currentExaminee.endTime | date}}）は終了しました。</p>
          </div>
          <div class="c-block" v-if="currentExaminee.tookExam">
            <p class="c-text__lg u-tac">{{currentExaminee.postExamMessage}}</p>
          </div>
        </template>
        <!---->
      </div>
      <div v-if="histories.length > 0" class="contents-block__footer u-tac">
        <button class="c-btn-test-result js-test-result" :class="{ 'is-open': showHistories }" type="button" @click="showHistories = !showHistories">
          <span class="label-close">過去の受検結果をみる</span>
          <span class="label-open">過去の受検結果を非表示にする</span>
        </button>
      </div>
    </div>
    <div v-if="histories.length > 0" class="contents-block has-green-border js-contents-test-result" :class="{ 'contents-test-result': !showHistories }">
      <div class="contents-block__body">
        <div class="c-block">
          <h2 class="c-heading__lv2"><img src="/assets/img/icon-book.svg" alt="">過去の受検結果</h2>
          <table class="c-table is-green">
            <thead>
              <tr>
                <th>受検日</th>
                <th>受検級</th>
                <th>合否</th>
                <th>スコアレポート</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="h in histories" :key="h.examineeId">
                <td><span class="sp-label">受検日</span><span class="sp-data">{{h.examTime | date}}</span></td>
                <td><span class="sp-label">受検級</span><span class="sp-data">{{h.gradeName}}</span></td>
                <td><span class="sp-label">合否</span><span class="sp-data">{{h.result ? h.result : '-'}}</span></td>
                <td><span class="sp-tac" v-if="h.result"><a class="c-link__text has-arrow" target="_blank" :href="'/download/endpoint/exam/score_report?examineeId=' + h.examineeId">スコアレポートを見る</a></span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="contents-block switch-member" v-if="members.length > 1">
      <div class="contents-block__header">
        <div class="c-block">
          <p class="switch-member-title"><span>会員切り替え</span></p>
        </div>
      </div>
      <div class="contents-block__body">
        <div class="c-block">
          <div class="c-form-btn-group">
            <div v-for="m in otherMembers" :key="m.memberId" class="c-form-btn-group__item">
              <button type="button" class="c-btn__secondary u-ma member-name" @click="switchMember(m.memberId)"><span>{{m.name}}さん</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form ref="cbtForm" action="" target="_blank"></form>
  </default-layout>
</template>

<script>
import {Service} from "./_resource";
import dayjs from "dayjs";
import {mapGetters, mapState} from "vuex";
import {DateUtil, MediaService} from "@/calico-vue/service";
import {authInfo} from "@/lib/service";

export default {
  data() {
    return {
      currentExaminee: false,
      canStartExam: false,
      histories: [],
      showHistories: null,
      examineesLoaded: false,
      dayjs
    };
  },
  computed: {
    ...mapState([
      "members",
      "currentMember"
    ]),
    ...mapGetters([
      "otherMembers"
    ])
  },
  created() {
    Service.getMembers().then(members => {
      this.$store.commit("setMembers", members);
      this.updateExaminees(true);
    })
  },
  mounted: function () {
    window.addEventListener('focus', this.updateExaminees);
  },
  beforeDestroy: function () {
    window.removeEventListener('focus', this.updateExaminees)
  },
  methods: {
    updateExaminees() {
      const member = this.$store.state.currentMember;
      this.histories = [];
      this.examineesLoaded = false;
      Service.getExaminees(member.memberId).then(examinees => {
        this.examineesLoaded = true;
        if (examinees.length == 0) {
          this.currentExaminee = false;
          return;
        }
        const latest = examinees[0];
        const now = new Date().getTime();
        if (Date.parse(latest.endTime) < now
            && latest.result != null) {
          this.histories = examinees;
          if (this.showHistories === null) this.showHistories = true;
          this.currentExaminee = false;
          return;
        }
        const started = Date.parse(latest.startTime) < now;
        const ended = Date.parse(latest.endTime) < now;
        const inProgress = started && !ended;
        const tookExam = latest.examTime != null;
        this.currentExaminee = Object.assign({ started, ended, inProgress, tookExam }, latest);
        console.log(this.currentExaminee);
        examinees.shift();
        this.histories = examinees;
      });
    },
    startExam() {
      const form = this.$refs.cbtForm;
      form.action = '/exam/' + this.currentExaminee.examineeId;
      form.submit();
    },
    downloadScoreReport(examineeId) {
      Service.downloadScoreReport(examineeId).then(media => {
        MediaService.download(media.pdf);
      });
    },
    switchMember(memberId) {
      this.$store.commit("chooseMember", memberId);
      this.updateExaminees();
    },
    isBridge() {
      return authInfo.isBridge;
    },
  },
  filters: {
    date(value) {
      return DateUtil.format(value, "YYYY年M月D日")
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/foundation/_mixin.scss";
.switch-member {
  .contents-block__header {
    padding: 20px 30px;
  }
  .c-form-btn-group {
    justify-content: center;
    flex-wrap: wrap;
    max-width: none;
    &__item {
      width: auto;
      margin: 10px 0;
    }
  }
  .contents-block__body {
    background-color: #FFFFFF;
    border-top: 1px solid #e0e0e0;
    padding: 20px 30px;
  }
  .switch-member-title {
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(pc) {
      min-width: 220px;
    }
    span {
      position: relative;
      background-image: url(/assets/img/icon-user.svg);
      background-repeat: no-repeat;
      background-position: left center;
      min-height: 24px;
      padding: 5px 20px 5px 30px;
      @include breakpoint(pc) {
        font-size: 1.5rem;
      }
    }
  }
  .member-name {
    color: #3B4043;
    font-weight: normal;
    font-size: 1.5rem;
    padding: 0 25px;
    &::before {
      content: "";
      top: 50%;
      display: inline-block;
      width: 5px;
      height: 9px;
      background-image: url(/assets/img/icon-arrow.svg);
      background-repeat: no-repeat;
      margin-top: 2px;
      margin-right: 7px;
    }
  }
}
.pdf-link {
  background-image: url(/assets/img/PDF_24.png);
  background-repeat: no-repeat;
  padding-left: 28px;
}
</style>
