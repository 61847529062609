import {DefaultAuthInfo} from "@/calico-vue/auth/DefaultAuthInfo";
import {safeAccess} from "@/calico-vue/service";
import isArray from "sugar/object/isArray";

class AuthInfo extends DefaultAuthInfo {
  get userName() {
    return safeAccess(this.authData, "userName");
  }

  get userType() {
    return safeAccess(this.authData, "userType");
  }

  get authorities() {
    return safeAccess(this.authData, "authorities") || [];
  }
  isAccessible(authorities) {
    const requiredAuthorities = isArray(authorities) ? authorities : [authorities];
    return requiredAuthorities
      .map(req => this.authorities.find(a => a.id === req) != null)
      .reduce((prev, curr) => prev && curr, true);
  }
}

export const authInfo = new AuthInfo();
